import React, { useRef, useEffect } from 'react';
import { easepick } from '@easepick/core';
import { RangePlugin } from '@easepick/range-plugin';
import { AmpPlugin } from '@easepick/amp-plugin';
import { LockPlugin } from '@easepick/lock-plugin';
import { DateTime } from '@easepick/datetime';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';

interface ISearchRangePicker {
  onChange: any;
  checkin: any;
  checkout: any;
  testScopeB?: boolean;
}

function SearchRangePicker({
  onChange,
  checkin,
  checkout,
  testScopeB,
}: ISearchRangePicker) {
  const checkinRef = useRef(null);
  const checkoutRef = useRef(null);

  useEffect(() => {
    const picker = new easepick.create({
      element: checkinRef.current,
      css: [
        'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
        '/staticmyapp/styles/easepick.css',
      ],
      zIndex: 10,
      format: 'MM/DD/YYYY',
      calendars: 2,
      header: testScopeB
        ? null
        : `When will you pick up and drop off your vehicle?`,
      grid: 2,
      AmpPlugin: {
        resetButton: true,
        darkMode: false,
      },
      RangePlugin: {
        elementEnd: checkoutRef.current,
        startDate: checkin ? new DateTime(dayjs(checkin).toDate()) : null,
        endDate: checkout ? new DateTime(dayjs(checkout).toDate()) : null,
        tooltip: testScopeB ? false : true,
      },
      LockPlugin: {
        minDate: new Date(),
        minDays: 2,
        inseparable: true,
      },
      plugins: [RangePlugin, AmpPlugin, LockPlugin],
      setup(picker) {
        picker.on('select', () => {
          if (onChange) {
            const startDate = picker.getStartDate();
            const endDate = picker.getEndDate();
            const formattedDates = [
              startDate
                ? dayjs(startDate.toJSDate()).format('MM/DD/YYYY')
                : null,
              endDate ? dayjs(endDate.toJSDate()).format('MM/DD/YYYY') : null,
            ];
            onChange(formattedDates);
          }
        });
      },
    });

    return () => picker.destroy(); // Cleanup on unmount
  }, [checkin, checkout, onChange, testScopeB]);

  return (
    <div className="hidden md:flex flex-row gap-x-2 search-easepick">
      <Tooltip
        title="When will you drop off your vehicle?"
        overlayClassName="search-tooltip"
      >
        <input
          className="h-10 border rounded-md px-3 w-full cursor-pointer"
          placeholder="Start Date"
          ref={checkinRef}
          id="checkin"
        />
      </Tooltip>
      <Tooltip
        title="When will you pick up your vehicle?"
        overlayClassName="search-tooltip"
      >
        <input
          className="h-10 border rounded-md px-3 w-full cursor-pointer"
          placeholder="End Date"
          ref={checkoutRef}
          id="checkout"
        />
      </Tooltip>
    </div>
  );
}

export default SearchRangePicker;
